import { FC, useEffect } from 'react'
import { BrowserRouter } from 'react-router'
import { UserProvider } from './context/UserContext'
import Router from './components/00_fundaments/Router/Router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useTranslation } from 'react-i18next'
import useStore from './store/useStore'
import Language from './structures/Enums/Language.enum'

type AppProperties = Record<string, never>

const App: FC<AppProperties> = () => {
    const { i18n } = useTranslation()
    const storedLanguage = useStore((state) => state.language)

    useEffect(() => {
        void i18n.changeLanguage(storedLanguage ?? Language.EN)
    }, [storedLanguage, i18n])

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    })

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <UserProvider>
                    <Router />
                </UserProvider>
            </BrowserRouter>
            <ToastContainer />
        </QueryClientProvider>
    )
}

export default App
