import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

import Language from './structures/Enums/Language.enum'

// eslint-disable-next-line no-void
void i18n
    // use translation files
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json`,
        },
        debug: false,
        ns: ['translation'],
        defaultNS: 'translation',
        load: 'languageOnly',
        cleanCode: true,
        lng: Language.EN,
        fallbackLng: Language.NL,
        supportedLngs: [Language.EN, Language.NL, Language.FR],
        nonExplicitSupportedLngs: true,
        keySeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        returnEmptyString: false,
    })

i18n.on('languageChanged', (chosenLanguage) => {
    document.documentElement.setAttribute('lang', chosenLanguage)
})

// eslint-disable-next-line no-restricted-exports
export { default } from 'i18next'
