import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import createUserSlice, { IUserSlice } from './userSlice'
import drawerMenuSlice, { IDrawerMenuSlice } from './drawerMenuSlice'

// Define the combined store type
type StoreState = IUserSlice & IDrawerMenuSlice

const useStore = create<StoreState>()(
    devtools(
        persist(
            (...a) => ({
                ...createUserSlice(...a),
                ...drawerMenuSlice(...a),
            }),
            {
                name: 'partner-store',
                partialize: (state) => ({ secret: state.secret, language: state.language }),
            }
        )
    )
)

export default useStore
