import { useQueryClient } from '@tanstack/react-query'
import { FC, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router'
import { toast } from 'react-toastify'
import { uploadScanSessionImage } from 'src/api/client/scansessions/ScansessionsActions'
import Button, { ButtonStyle } from 'src/components/01_atoms/Button/Button'
import FileUploader, { FileUploaderRef } from 'src/components/01_atoms/FileUploader/FileUploader'
import PlusIcon from 'src/components/01_atoms/Icon/PlusIcon'
import Picture from 'src/components/01_atoms/Picture/Picture'
import Header from 'src/components/02_molecules/Header/Header'
import Hero from 'src/components/02_molecules/Hero/Hero'
import Page from 'src/components/03_organisms/Page/Page'
import QueryKey from 'src/structures/Enums/QueryKey.enum'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import IMenuScannerFile from 'src/structures/Interfaces/IMenuScannerFile'
import { IScansessionByIdResponse } from 'src/structures/Interfaces/IScansessionsResponse'
import { filesToBase64 } from 'src/utils/file.utils'

interface IMenuScannerUploadProperties {
    scanSession: IScansessionByIdResponse
}

const MenuScannerUpload: FC<IMenuScannerUploadProperties> = ({ scanSession }) => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const fileUploaderRef = useRef<FileUploaderRef>(null)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const uploadAllImages = async (base64Files: IMenuScannerFile[], uploadFunction: typeof uploadScanSessionImage) => {
        const uploadPromises = base64Files.map(async (base64File: IMenuScannerFile) =>
            uploadFunction(scanSession.id, base64File)
        )
        return Promise.all(uploadPromises)
    }

    const handleBeginUpload = useCallback(
        (files: File[]) => {
            async function startUpload() {
                setIsSubmitting(true)
                const imagesPostData = await filesToBase64(files)
                await uploadAllImages(imagesPostData ?? [], uploadScanSessionImage)
                    .then(() => void queryClient.invalidateQueries({ queryKey: [QueryKey.SCAN_SESSION] }))
                    .catch(() => toast.error(t('general.somethingWentWrong')))
                    .finally(() => setIsSubmitting(false))
            }
            void startUpload()
        },
        [scanSession]
    )

    const handleFileSelect = useCallback(() => {
        if (fileUploaderRef.current) {
            // Trigger the file input click to start selecting files (or take photo)
            fileUploaderRef.current.click()
        }
    }, [fileUploaderRef?.current])

    return (
        <Page
            header={
                <Header
                    backButton={RoutePath.ROOT}
                    title={t('scansession.upload.header.title')}
                />
            }
            footer={
                <div className='h-28'>
                    <div className='fixed flex items-center justify-center bottom-0 left-0 right-0 p-6'>
                        {scanSession.scan_images?.length > 0 ? (
                            <Link
                                className='block w-1/3 max-w-screen-lg mr-2'
                                to={RoutePath.ROOT}>
                                <Button
                                    loading={isSubmitting}
                                    className='w-full !bg-gray-100 !text-gray-500'
                                    buttonStyle={ButtonStyle.SECONDARY}>
                                    {t('scansession.upload.button.quit', 'Quit')}
                                </Button>
                            </Link>
                        ) : null}
                        <Button
                            loading={isSubmitting}
                            className='w-full max-w-screen-lg overflow-hidden'
                            buttonStyle={ButtonStyle.PRIMARY}
                            onClick={handleFileSelect}>
                            {!isSubmitting ? <PlusIcon className='size-4 min-w-4 m-0 mr-2' /> : null}
                            <span className='overflow-hidden text-ellipsis inline'>
                                {isSubmitting
                                    ? t('scansession.upload.button.loading')
                                    : scanSession.scan_images?.length > 0
                                      ? t('scansession.upload.button.addMoreImages')
                                      : t('scansession.upload.button.addImages')}
                            </span>
                        </Button>
                    </div>
                </div>
            }>
            <Hero>
                <h1 className='h3 text-center'>{t('scansession.upload.title')}</h1>
            </Hero>
            <FileUploader
                multiple
                accept={['image/png', 'image/jpeg', 'image/jpg']}
                ref={fileUploaderRef}
                onFilesSelected={handleBeginUpload}
            />
            {scanSession.scan_images?.length > 0 ? (
                <section className='grid grid-cols-2 lg:grid-cols-3 gap-2 px-6'>
                    {scanSession.scan_images.map((image) => (
                        <div
                            key={image.id}
                            className='bg-white rounded-md object-contain aspect-square w-full p-2 shadow-sm'>
                            <Picture
                                src={image.image_url}
                                className='w-full h-full'
                            />
                        </div>
                    ))}
                </section>
            ) : null}
        </Page>
    )
}

export default MenuScannerUpload
