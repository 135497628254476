import { FC, ReactNode, useCallback, useState } from 'react'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

interface IPictureProperties {
    src: string
    loading?: 'lazy' | 'eager' | undefined
    alt?: string
    className?: string
    fallback?: ReactNode
}

const Picture: FC<IPictureProperties> = ({ src, alt, className, loading, fallback }) => {
    const [imageLoading, setImageLoading] = useState<boolean>(true)
    const [loadError, setLoadError] = useState<boolean>(false)

    const imageLoaded = useCallback(() => {
        setImageLoading(false)
    }, [])

    const imageLoadFailed = useCallback(() => {
        setLoadError(true)
    }, [])

    if (loadError && isNotNullOrUndefined(fallback)) {
        return <>{fallback}</>
    }

    if (loadError) {
        return null
    }

    return (
        <picture
            style={{ pointerEvents: 'none' }}
            className={className}>
            <img
                loading={loading}
                className={
                    imageLoading ? 'w-full h-full opacity-0' : 'w-full h-full opacity-1 object-contain animate-fadeIn'
                }
                onLoad={imageLoaded}
                onError={imageLoadFailed}
                src={src}
                alt={alt ?? 'photo'}
            />
        </picture>
    )
}

export default Picture
