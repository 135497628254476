import axios from 'axios'
import { isApiResourceWrapped } from 'src/structures/Interfaces/IApiResourceWrapper'

export const GET = async <ResponseType>(endpoint: string) =>
    new Promise((resolve: (value: ResponseType) => void, reject: (error: unknown) => void) => {
        // eslint-disable-next-line no-void
        void (async () => {
            try {
                const { data: responseData } = await axios.get<ResponseType>(endpoint)
                resolve(isApiResourceWrapped<ResponseType>(responseData) ? responseData.data : responseData)
            } catch (error: unknown) {
                if (axios.isAxiosError(error)) {
                    reject(error)
                } else if (error instanceof Error) {
                    reject(error)
                } else {
                    reject(new Error(`Unexpected error: ${String(error)}`)) // Wrap non-Error values
                }
            }
        })()
    })

export const POST = async <ResponseType, BodyType>(endpoint: string, data: BodyType) =>
    new Promise((resolve: (value: ResponseType) => void, reject: (error: unknown) => void) => {
        // eslint-disable-next-line no-void
        void (async () => {
            try {
                const { data: responseData } = await axios.post<ResponseType>(endpoint, data)
                resolve(isApiResourceWrapped<ResponseType>(responseData) ? responseData.data : responseData)
            } catch (error: unknown) {
                if (axios.isAxiosError(error)) {
                    reject(error)
                } else if (error instanceof Error) {
                    reject(error)
                } else {
                    reject(new Error(`Unexpected error: ${String(error)}`)) // Wrap non-Error values
                }
            }
        })()
    })

export const PUT = async <ResponseType, BodyType>(endpoint: string, data: BodyType) =>
    new Promise((resolve: (value: ResponseType) => void, reject: (error: unknown) => void) => {
        // eslint-disable-next-line no-void
        void (async () => {
            try {
                const { data: responseData } = await axios.put<ResponseType>(endpoint, data)
                resolve(isApiResourceWrapped<ResponseType>(responseData) ? responseData.data : responseData)
            } catch (error: unknown) {
                if (axios.isAxiosError(error)) {
                    reject(error)
                } else if (error instanceof Error) {
                    reject(error)
                } else {
                    reject(new Error(`Unexpected error: ${String(error)}`)) // Wrap non-Error values
                }
            }
        })()
    })

export const DELETE = async <ResponseType>(endpoint: string) =>
    new Promise((resolve: (value: ResponseType) => void, reject: (error: unknown) => void) => {
        // eslint-disable-next-line no-void
        void (async () => {
            try {
                const { data: responseData } = await axios.delete<ResponseType>(endpoint)
                resolve(isApiResourceWrapped<ResponseType>(responseData) ? responseData.data : responseData)
            } catch (error: unknown) {
                if (axios.isAxiosError(error)) {
                    reject(error)
                } else if (error instanceof Error) {
                    reject(error)
                } else {
                    reject(new Error(`Unexpected error: ${String(error)}`)) // Wrap non-Error values
                }
            }
        })()
    })

export const PATCH = async <ResponseType, BodyType>(endpoint: string, data: BodyType) =>
    new Promise((resolve: (value: ResponseType) => void, reject: (error: unknown) => void) => {
        // eslint-disable-next-line no-void
        void (async () => {
            try {
                const { data: responseData } = await axios.patch<ResponseType>(endpoint, data)
                resolve(isApiResourceWrapped<ResponseType>(responseData) ? responseData.data : responseData)
            } catch (error: unknown) {
                if (axios.isAxiosError(error)) {
                    reject(error)
                } else if (error instanceof Error) {
                    reject(error)
                } else {
                    reject(new Error(`Unexpected error: ${String(error)}`)) // Wrap non-Error values
                }
            }
        })()
    })
