import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router'
import DrawerMenu from 'src/components/03_organisms/DrawerMenu/DrawerMenu'
import { useUserContext } from 'src/context/UserContext'
import useStore from 'src/store/useStore'
import Language from 'src/structures/Enums/Language.enum'

type IRootLayoutProperties = Record<string, null>

const RootLayout: FC<IRootLayoutProperties> = () => {
    const { user } = useUserContext()
    const { i18n } = useTranslation()
    const isDrawerMenuOpen = useStore((state) => state.isDrawerMenuOpen)
    const setDrawerMenuOpen = useStore((state) => state.setDrawerMenuIsOpen)
    const setStoredLanguage = useStore((state) => state.setLanguage)

    const supportedLangs = (i18n.options.supportedLngs as string[]) ?? Language.EN

    const handleChangeLanguage = useCallback(
        (language: Language) => {
            setStoredLanguage(language)
            setDrawerMenuOpen(false)
        },
        [setStoredLanguage]
    )

    return (
        <main className='w-full mx-auto max-w-screen-lg'>
            <Outlet />
            {isDrawerMenuOpen ? (
                <DrawerMenu>
                    <div className='rounded-xl m-6 bg-white shadow-sm overflow-hidden'>
                        <div className='p-6'>
                            <h2 className='h3'>{`${user?.firstname} ${user?.name}`}</h2>
                            <p className='text-sm text-slate-600'>{user?.supplier?.name}</p>
                        </div>
                        <div className='flex justify-items-stretch items-stretch border-t divide-x divide-dashed'>
                            {supportedLangs
                                .filter((item) => item !== 'cimode')
                                .map((language) => (
                                    <button
                                        key={language}
                                        className={`${language === i18n.languages[0] ? 'bg-rose-500 text-white font-bold' : 'text-gray-400'} w-full p-4 overflow-hidden`}
                                        onClick={() => handleChangeLanguage(language as Language)}>
                                        {language.toUpperCase()}
                                    </button>
                                ))}
                        </div>
                    </div>
                </DrawerMenu>
            ) : null}
        </main>
    )
}

export default RootLayout
