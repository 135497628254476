import IAddress from 'src/structures/Interfaces/IAddress'
import IGooglePlaceResult from 'src/structures/Interfaces/IGooglePlaceResult'
import { isNotNullOrUndefined } from './guards.utils'

const splitAddressFromGeoCoderResult = (placeResult: google.maps.places.PlaceResult): Partial<IAddress> =>
    placeResult.address_components?.reduce<Partial<IAddress>>(
        (accumulator, { types, long_name: value, short_name: shortValue }) => {
            const type = types[0]

            switch (type) {
                case 'route': {
                    return { ...accumulator, route: value }
                }
                case 'street_number': {
                    return { ...accumulator, street_number: value }
                }
                case 'locality': {
                    return { ...accumulator, locality: value }
                }
                case 'country': {
                    return { ...accumulator, country: value, country_iso: shortValue }
                }
                case 'postal_code': {
                    return { ...accumulator, postal_code: value }
                }
                default: {
                    return accumulator
                }
            }
        },
        {}
    ) ?? {}

export function googlePlaceResultToAddress(result: IGooglePlaceResult): IAddress | null {
    if (isNotNullOrUndefined(result)) {
        const addressInfo = splitAddressFromGeoCoderResult(result)
        const dataToSave: IAddress = {
            place_id: result.place_id ?? null,
            route: addressInfo.route ?? null,
            street_number: addressInfo.street_number ?? null,
            street_box: addressInfo.street_box ?? null,
            postal_code: addressInfo.postal_code ?? null,
            locality: addressInfo.locality ?? null,
            lat: result.geometry?.location?.lat() ?? null,
            lng: result.geometry?.location?.lng() ?? null,
            country: addressInfo.country ?? null,
            country_iso: addressInfo.country_iso ?? null,
            formatted_address: result.formatted_address ?? null,
            types: result.types ?? [],
        }

        return dataToSave
    }
    return null
}
