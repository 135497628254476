import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router'
import { getScanSessions } from 'src/api/client/scansessions/ScansessionsActions'
import BarsArrowDownIcon from 'src/components/01_atoms/Icon/BarsArrowDownIcon'
import BarsArrowUpIcon from 'src/components/01_atoms/Icon/BarsArrowUpIcon'
import ChevronIcon from 'src/components/01_atoms/Icon/ChevronIcon'
import LoadingSpinner from 'src/components/01_atoms/LoadingSpinner/LoadingSpinner'
import QueryKey from 'src/structures/Enums/QueryKey.enum'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import { IScansessionsResponse } from 'src/structures/Interfaces/IScansessionsResponse'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

type IMenuScannerHistoryProperties = Record<string, null>

const MenuScannerHistory: FC<IMenuScannerHistoryProperties> = () => {
    const { t } = useTranslation()
    const [isOrderReversed, setIsOrderReversed] = useState<boolean>(true)

    const { isLoading, data: scansessions } = useQuery<IScansessionsResponse[], AxiosError>({
        queryKey: [QueryKey.SCAN_SESSIONS],
        queryFn: getScanSessions,
        //select: (userResponse) => new User(userResponse),
        enabled: true,
        retry: 1,
    })

    const toggleOrder = useCallback(() => {
        setIsOrderReversed((prev) => !prev)
    }, [])

    if (isLoading) {
        return (
            <section className='rounded-xl mx-2 bg-white shadow-sm flex justify-center items-center min-h-20'>
                <LoadingSpinner />
            </section>
        )
    }

    if (isNotNullOrUndefined(scansessions) && scansessions.length > 0) {
        const scanSessionsOrdered = isOrderReversed ? [...scansessions].reverse() : scansessions

        return (
            <section className='rounded-xl mx-2 bg-white shadow-sm'>
                <table className='table-auto min-w-full max-w-full rounded-xl overflow-hidden w-full'>
                    <thead className='border-b'>
                        <tr className='bg-gray-50'>
                            <th className='p-5 py-2 text-left text-sm leading-6 font-semibold text-gray-900'>
                                {t('scanhistory.title')}
                            </th>
                            <th className='p-5 pr-4 py-2 text-right text-sm leading-6 font-semibold text-gray-900'>
                                <button
                                    className='size-6 align-middle opacity-50'
                                    type='button'
                                    onClick={toggleOrder}>
                                    {isOrderReversed ? (
                                        <BarsArrowUpIcon className='size-full text-gray-900' />
                                    ) : (
                                        <BarsArrowDownIcon className='size-full text-gray-900' />
                                    )}
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody className='divide-y divide-dashed'>
                        {scanSessionsOrdered.map((session) => (
                            <tr key={session.id}>
                                <td
                                    colSpan={2}
                                    className='p-5 whitespace-wrap text-sm leading-6 text-gray-900'>
                                    <Link
                                        className='text-gray-900'
                                        to={RoutePath.MENU_SCANNER.replace(':sessionId', session.id)}>
                                        <div className='flex justify-between items-center'>
                                            <div>
                                                <div className='leading-tight font-semibold'>
                                                    {isNotNullOrUndefined(session.location?.name)
                                                        ? session.location.name
                                                        : 'No Venue name'}
                                                </div>
                                                <div className='text-xs leading-tight mt-1 text-gray-400'>
                                                    {isNotNullOrUndefined(session.location?.address) ? (
                                                        <span className='block'>
                                                            {session.location?.address?.formatted_address}
                                                        </span>
                                                    ) : null}
                                                    <span className='block'>{session.created_at}</span>
                                                </div>
                                            </div>

                                            <div className='flex justify-center'>
                                                <div className='flex items-center justify-center'>
                                                    <div className='size-4 rounded-full ml-5  text-rose-600'>
                                                        <ChevronIcon direction='right' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot className='border-t border-dashed'>
                        <tr>
                            <td
                                colSpan={2}
                                className='p-2 bg-gray-50 whitespace-wrap text-center text-sm leading-6 text-gray-500'>
                                {t('scanhistory.count', { count: scansessions.length })}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </section>
        )
    }

    return (
        <section className='flex items-center min-h-60 rounded-xl m-2 p-6 text-center text-gray-400 bg-white shadow-sm'>
            <div className='m-auto'>
                <h3 className='h3'>{t('scanhistory.empty.title')}</h3>
                <p>{t('scanhistory.empty.body')}</p>
            </div>
        </section>
    )
}

export default MenuScannerHistory
