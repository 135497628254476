import { StateCreator } from 'zustand'

const initialState: { isDrawerMenuOpen: boolean } = {
    isDrawerMenuOpen: false,
}

export interface IDrawerMenuSlice {
    isDrawerMenuOpen: boolean
    setDrawerMenuIsOpen: (isOpen: boolean) => void
}

const drawerMenuSlice: StateCreator<IDrawerMenuSlice> = (set) => ({
    ...initialState,
    setDrawerMenuIsOpen: (isOpen: boolean) => set(() => ({ isDrawerMenuOpen: isOpen })),
})

export default drawerMenuSlice
