export default interface IApiResourceWrapper<T> {
    data: T
}

export const isApiResourceWrapped = <T>(object: unknown): object is IApiResourceWrapper<T> =>
    typeof object === 'object' &&
    object !== null &&
    // eslint-disable-next-line no-prototype-builtins
    object.hasOwnProperty('data') &&
    // eslint-disable-next-line no-prototype-builtins
    !object.hasOwnProperty('meta')
