import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FC } from 'react'
import { Navigate, useParams } from 'react-router'
import { getScanSessionById } from 'src/api/client/scansessions/ScansessionsActions'
import QueryKey from 'src/structures/Enums/QueryKey.enum'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import { IScansessionByIdResponse } from 'src/structures/Interfaces/IScansessionsResponse'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'
import MenuScannerLocation from '../MenuScannerLocation/MenuScannerLocation'
import MenuScannerUpload from '../MenuScannerUpload/MenuScannerUpload'
import LoadingScreen from '../LoadingScreen/LoadingScreen'

type IMenuScannerSessionProperties = Record<string, null>

const MenuScannerSession: FC<IMenuScannerSessionProperties> = () => {
    const { sessionId } = useParams()

    const { isLoading, data: scansession } = useQuery<IScansessionByIdResponse, AxiosError>({
        queryKey: [QueryKey.SCAN_SESSION, sessionId],
        queryFn: async () => await getScanSessionById(sessionId ?? ''),
        enabled: isNotNullOrUndefined(sessionId),
        retry: 1,
    })

    if (isLoading) {
        return <LoadingScreen />
    }

    if (!isLoading && isNotNullOrUndefined(scansession)) {
        if (isNotNullOrUndefined(scansession.location)) {
            return <MenuScannerUpload scanSession={scansession} />
        }
        return <MenuScannerLocation scanSession={scansession} />
    }

    return (
        <Navigate
            to={RoutePath.ROOT}
            replace
        />
    )
}

export default MenuScannerSession
