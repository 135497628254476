import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'
import SearchIcon from '../Icon/SearchIcon'

interface IGooglePlaceAutoCompleteInputProperties {
    placeHolder: string
    onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void
    options?: google.maps.places.AutocompleteOptions
    disabled?: boolean
}

export const GooglePlaceAutoCompleteInput: FC<IGooglePlaceAutoCompleteInputProperties> = ({
    placeHolder,
    onPlaceSelect,
    options,
    disabled,
}) => {
    const [mapUrl, setMapUrl] = useState<string | undefined>(undefined)
    const [, setIsInputModified] = useState<boolean>(false)

    const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null)
    const inputReference = useRef<HTMLInputElement>(null)
    const places = useMapsLibrary('places')

    useEffect(() => {
        if (!isNotNullOrUndefined(places) || !isNotNullOrUndefined(inputReference?.current)) return
        if (isNotNullOrUndefined(placeAutocomplete)) return

        const autoCompleteOptions: google.maps.places.AutocompleteOptions = {
            types: ['establishment', 'geocode'],
            fields: ['place_id', 'geometry', 'address_components', 'formatted_address', 'types', 'name'],
            ...options,
        }
        setPlaceAutocomplete(new places.Autocomplete(inputReference.current, autoCompleteOptions))
    }, [places, options, placeAutocomplete])

    useEffect(() => {
        if (!isNotNullOrUndefined(placeAutocomplete)) return

        placeAutocomplete.addListener('place_changed', () => {
            const place = placeAutocomplete.getPlace()

            onPlaceSelect(place)
            setMapUrl(
                `https://www.google.com/maps/embed/v1/place?key=${import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=place_id:${place?.place_id}`
            )
            setIsInputModified(false)
        })
    }, [onPlaceSelect, placeAutocomplete])

    // reset map and value when input is changed (user needs to select an option from the autocomplete)
    const handleInputChange = useCallback(() => {
        setIsInputModified(true)
        setMapUrl(undefined)
        onPlaceSelect(null)
    }, [onPlaceSelect])

    return (
        <div className='px-6'>
            <div className='relative'>
                <input
                    disabled={disabled}
                    placeholder={placeHolder}
                    className="h-12 pl-12 block w-full rounded-md 'border-red-500'shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    ref={inputReference}
                    onInput={handleInputChange}
                />
                <div className='absolute top-0 left-0 bottom-0 w-12 flex justify-center items-center pointer-events-none'>
                    <SearchIcon className='size-7 block text-gray-400' />
                </div>
            </div>
            {isNotNullOrUndefined(mapUrl) ? (
                <div className='h-60 rounded-md overflow-hidden mt-2 border border-gray-500'>
                    <iframe
                        title='Venue Location'
                        height='350'
                        width='100%'
                        src={mapUrl}
                        className='overflow-hidden'
                        loading='lazy'
                        allowFullScreen
                        referrerPolicy='no-referrer-when-downgrade'
                    />
                </div>
            ) : null}
        </div>
    )
}

export default GooglePlaceAutoCompleteInput
