import { FC, useCallback } from 'react'
import { Link } from 'react-router'
import BarsIcon from 'src/components/01_atoms/Icon/BarsIcon'
import ChevronIcon from 'src/components/01_atoms/Icon/ChevronIcon'
import CloseIcon from 'src/components/01_atoms/Icon/CloseIcon'
import useStore from 'src/store/useStore'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

interface IHeaderProperties {
    title?: string
    backButton?: string
}

const Header: FC<IHeaderProperties> = ({ title, backButton }) => {
    const isDrawerMenuOpen = useStore((state) => state.isDrawerMenuOpen)
    const setDrawerMenuIsOpen = useStore((state) => state.setDrawerMenuIsOpen)

    const handleToggleMenu = useCallback(() => {
        setDrawerMenuIsOpen(!isDrawerMenuOpen)
    }, [setDrawerMenuIsOpen, isDrawerMenuOpen])

    return (
        <div className='h-14'>
            <header className='fixed z-50 top-0 left-0 w-full flex items-center justify-between bg-black h-14 p-4'>
                {isNotNullOrUndefined(backButton) ? (
                    <Link
                        className='block size-7'
                        to={backButton}>
                        <button type='button'>
                            <ChevronIcon
                                direction='left'
                                className='size-7 text-white'
                            />
                        </button>
                    </Link>
                ) : (
                    <div className='size-7' />
                )}
                {isNotNullOrUndefined(title) ? (
                    <p className='text-white text-center overflow-hidden text-ellipsis px-4 m-0'>{title}</p>
                ) : null}
                <button
                    onClick={handleToggleMenu}
                    type='button'>
                    {isDrawerMenuOpen ? (
                        <CloseIcon className='size-8 text-white' />
                    ) : (
                        <BarsIcon className='size-8 text-white' />
                    )}
                </button>
            </header>
        </div>
    )
}
export default Header
