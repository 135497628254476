import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

import { useTranslation } from 'react-i18next'
import LoadingSpinner from 'src/components/01_atoms/LoadingSpinner/LoadingSpinner'
import { useUserContext } from 'src/context/UserContext'
import useSecret from 'src/hooks/useSecret'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

interface IAuthenticationProperties {
    className?: string
}

// THIS IS PILOT ONLY, AUTH SHOULD HAPPEN VIA LOGIN FORM

const Authentication: FC<IAuthenticationProperties> = ({ className }) => {
    const { t } = useTranslation()
    const { secret } = useSecret()
    const { isLoading, isError } = useUserContext()

    if ((!isLoading && isError) || !isNotNullOrUndefined(secret)) {
        return (
            <div className={twMerge('fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full', className)}>
                <div className='flex items-center flex-col justify-between w-full'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='currentColor'
                        className='size-8 text-gray-500'>
                        <path
                            fillRule='evenodd'
                            d='M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z'
                            clipRule='evenodd'
                        />
                    </svg>
                    <p className='text-sm mt-2 text-gray-500'>{t('general.accessDenied', 'Access Denied')}</p>
                </div>
            </div>
        )
    }

    return (
        <div className={twMerge('fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2', className)}>
            <LoadingSpinner className='w-12 h-12' />
        </div>
    )
}

export default Authentication
