import { FC } from 'react'
import { Route, Routes } from 'react-router'
import MenuScannerDashboard from 'src/components/04_pages/MenuScannerDashboard/MenuScannerDashboard'
import MenuScannerSession from 'src/components/04_pages/MenuScannerSession/MenuScannerSession'
import NotFound from 'src/components/04_pages/NotFound/NotFound'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import RootLayout from '../RootLayout/RootLayout'

type IAuthenticatedRoutesProperties = Record<string, null>

const AuthenticatedRoutes: FC<IAuthenticatedRoutesProperties> = () => (
    <Routes>
        <Route element={<RootLayout />}>
            <Route
                index
                path={RoutePath.ROOT}
                element={<MenuScannerDashboard />}
            />
            <Route
                path={RoutePath.MENU_SCANNER}
                element={<MenuScannerSession />}
            />
            <Route
                path={RoutePath.PAGE_NOT_FOUND}
                element={<NotFound />}
            />
        </Route>
    </Routes>
)

export default AuthenticatedRoutes
