import { GET, POST } from 'src/api/utilities/methods.api'
import {
    ISetScanSessionLocation,
    IScansessionByIdResponse,
    IScansessionByIdUploadImagePost,
    IScansessionCreateResponse,
    IScansessionsResponse,
} from 'src/structures/Interfaces/IScansessionsResponse'
import {
    SCAN_SESSION_BY_ID_ENDPOINT,
    SCAN_SESSION_SET_LOCATION,
    SCAN_SESSION_UPLOAD_IMAGE,
    SCAN_SESSIONS_ENDPOINT,
} from './ScansessionsEndpoints'

export const getScanSessions = async () => {
    return GET<IScansessionsResponse[]>(SCAN_SESSIONS_ENDPOINT)
}

export const getScanSessionById = async (sessionId: string) => {
    return GET<IScansessionByIdResponse>(SCAN_SESSION_BY_ID_ENDPOINT(sessionId))
}

export const createScanSession = async () => {
    return POST<IScansessionCreateResponse, null>(SCAN_SESSIONS_ENDPOINT, null)
}

export const SetScanSessionLocation = async (sessionId: string, patchData: ISetScanSessionLocation) => {
    return POST<void, ISetScanSessionLocation>(SCAN_SESSION_SET_LOCATION(sessionId), patchData)
}

export const uploadScanSessionImage = async (sessionId: string, postData: IScansessionByIdUploadImagePost) => {
    return POST<void, IScansessionByIdUploadImagePost>(SCAN_SESSION_UPLOAD_IMAGE(sessionId), postData)
}
