import Language from 'src/structures/Enums/Language.enum'
import { StateCreator } from 'zustand'

const initialState: { secret: string | undefined; language: Language } = {
    secret: undefined,
    language: Language.EN,
}

export interface IUserSlice {
    secret: string | undefined
    language: Language
    setSecret: (newSecret: string) => void
    setLanguage: (newLanguage: Language) => void
    removeSecret: () => void
}

const createUserSlice: StateCreator<IUserSlice> = (set) => ({
    ...initialState,
    setSecret: (newSecret: string) => set(() => ({ secret: newSecret })),
    setLanguage: (newLanguage: Language) => set(() => ({ language: newLanguage })),
    removeSecret: () => set(initialState),
})

export default createUserSlice
