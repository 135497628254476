/** RoutePaths should be ordered from most specific to least specific
 * because we loop over them to find matches in HeaderNavigationContext
 * e.g. /catalogue/products/new > /catalogue/products/:id
 */
enum RoutePath {
    ROOT = '/',
    LOGIN = '/login',
    MENU_SCANNER = '/scansessions/:sessionId',
    /* Keep this as the last entry */
    PAGE_NOT_FOUND = '*',
}

export default RoutePath
