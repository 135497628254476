import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { toast } from 'react-toastify'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

type AllowedFileTypes = 'image/png' | 'image/gif' | 'image/jpeg' | 'image/jpg' | 'image/webp' | 'image/svg+xml'

interface FileUploaderProps {
    accept: AllowedFileTypes[]
    multiple: boolean
    onFilesSelected?: (files: File[]) => void
}

// Define the type for the exposed methods of the ref
export interface FileUploaderRef {
    click: () => void
}

const FileUploader = forwardRef<FileUploaderRef, FileUploaderProps>(
    (
        {
            onFilesSelected,
            multiple = false,
            accept = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg', 'image/webp', 'image/svg+xml'],
        },
        ref
    ) => {
        const MAX_FILE_SIZE = 10 * 1024 * 1024
        const fileInputRef = useRef<HTMLInputElement>(null)

        // Expose the click method to the parent via ref
        useImperativeHandle(ref, () => ({
            click: () => {
                if (fileInputRef.current) {
                    fileInputRef.current.click()
                }
            },
        }))

        const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const files = isNotNullOrUndefined(event.target.files) ? [...event.target.files] : []
            if (isNotNullOrUndefined(files) && files.length > 0) {
                const validFiles: File[] = []
                const inValidFiles: File[] = []

                for (const file of files) {
                    if (file.size > MAX_FILE_SIZE) {
                        inValidFiles.push(file)
                    } else {
                        validFiles.push(file)
                    }
                }

                if (inValidFiles.length > 0) {
                    toast.error('Some files are too large! (max 10 MB)')
                }

                onFilesSelected?.(validFiles)

                if (isNotNullOrUndefined(fileInputRef?.current)) {
                    fileInputRef.current.value = ''
                }
            }
        }

        return (
            <input
                type='file'
                multiple={multiple}
                accept={accept.join(', ')}
                ref={fileInputRef}
                style={{ display: 'none', height: 0, width: '1px' }}
                onChange={handleFileChange}
            />
        )
    }
)

export default FileUploader
